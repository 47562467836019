var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"chatbot",staticClass:"yedai-chat twy-relative ",class:{
    'yedai-chat--errors': _vm.hasErrors,
    'yedai-chat--without-bubble': _vm.hideCloseBubble,
    'yedai-chat--avatar-bubble': _vm.bubbleWithImage
  },style:(_vm.transSendPlaceholder)},[_c('beautiful-chat',{style:(_vm.transSuggestions),attrs:{"always-scroll-to-bottom":_vm.alwaysScrollToBottom,"close":_vm.closeChat,"colors":_vm.colors,"is-open":_vm.isChatOpen && _vm.isLoaded && !_vm.hasErrors,"message-list":_vm.groupedMessageList,"on-message-was-sent":_vm.onMessageWasSent,"showTypingIndicator":_vm.innerShowTypingIndicator,"open":_vm.openChat,"participants":_vm.participants,"placeholder":_vm.placeholderText,"show-close-button":false,"show-launcher":true},on:{"onType":_vm.removePlaceholder,"edit":_vm.handleRecording},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.showRecordinMessages)?_c('AcceptlegalTerms',{attrs:{"policy-url":_vm.policyUrl},on:{"accept":_vm.setAcceptRecording}}):_c('Menu',{attrs:{"end-point":_vm.apiUrl,"title":_vm.titleHeader,"language-menu":_vm.languageMenu},on:{"closeChat":_vm.closeChat}}),(_vm.alertMessage.length != 0)?_c('InactivityAlert',{attrs:{"message":_vm.alertMessage},on:{"accept":_vm.restartChat}}):_vm._e(),(_vm.activeDialog)?_c('HandOver',{ref:"handoverDialog",attrs:{"textMessage":_vm.dialogMessage},on:{"accept":_vm.sendMail,"cancel":_vm.cancelHandOver}}):_vm._e()]},proxy:true},{key:"user-avatar",fn:function(ref){
  var message = ref.message;
return [(_vm.isUserAvatar(message))?[_vm._t("avatarUser")]:_vm._e(),(_vm.isBotAvatar(message))?[_vm._t("avatarBot")]:_vm._e()]}},{key:"text-message-body",fn:function(ref){
  var message = ref.message;
return [_c('ChatMessage',{attrs:{"message":message,"open":_vm.isChatOpen}})]}},{key:"system-message-body",fn:function(ref){
  var message = ref.message;
return [_c('ChatMessage',{attrs:{"message":{ data: message },"open":_vm.isChatOpen}})]}}],null,true)}),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasCta && !_vm.isChatOpen && !_vm.hasErrors)?_c('div',{staticClass:"yed-bubble-wrapper"},[_vm._t("callToAction")],2):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasErrors && !_vm.isChatOpen)?_c('div',{staticClass:"yed-bubble-wrapper"},[_vm._t("errorMessage")],2):_vm._e()]),_c('ChatIconList')],1)}
var staticRenderFns = []

export { render, staticRenderFns }